<template>
  <b-card
    v-if="respData && $ability.can('read', this.$route.meta.resource)"
    class="blog-edit-wrapper"
  >
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-overlay
        :show="loadingHtml"
        rounded="sm"
      >
        <b-form-group
          label="Html"
          label-for="blog-content"
          class="mb-2"
        >
          <!-- <quill-editor
                id="discipline-qe"
                ref="quillEdit"
                v-model="respData.htmlData"
                :options="editorOption"
              /> -->
          <b-form-file
            id="getFile"
            accept=".webp, .jpeg, .jpg, .png, .gif"
            :hidden="true"
            plain
            @change="uploadFunction($event)"
          />
        </b-form-group>
        <!-- <b-form-textarea
                  v-if="respData.html[indexLang]"
                  id="textarea-state"
                  :value="respData.html[indexLang]['value']"
                  rows="8"
                  @input="inputLanguage($event, itemLang.lang, indexLang, 'html')"
                /> -->
        <vue-editor
          :id="`editor-html`"
          v-model="respData.html"
          @imageAdded="handleImageAdded"
        />
      </b-overlay>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="saveChanges"
          >
            {{ $t("Save Changes") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="cancelBtn"
          >
            {{ $t("Cancel") }}
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,

  BRow,
  BCol,
  BFormGroup,

  BImg,
  BFormFile,
  BLink,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import StoreModule from '@/store/store-queue/about'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required } from '@validations'
// eslint-disable-next-line import/no-unresolved
import { VueEditor } from 'vue2-editor'

const STORE_MODULE_NAME = 'about'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,

    // quillEditor,
    // Editor,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BMediaAside,
    // eslint-disable-next-line vue/no-unused-components
    BMediaBody,

    BRow,
    BCol,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BButton,
    BOverlay,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataId: null,
      show: false,
      loadingHtml: false,
      respData: {

        html: '',
      },
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['link', 'image', 'video'],
            ],
            handlers: {
              image() {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      required,
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    this.get()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    get() {
      store
        .dispatch(`${STORE_MODULE_NAME}/get`)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result.data.data)
          const { about } = result.data.data
          this.respData.html = about
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      console.log('handleImageAdded')
      console.log(file)
      // var formData = new FormData();
      // formData.append("image", file);

      // axios({
      //   url: "https://fakeapi.yoursite.com/images",
      //   method: "POST",
      //   data: formData
      // })
      //   .then(result => {
      //     const url = result.data.url; // Get url from response
      //     Editor.insertEmbed(cursorLocation, "image", url);
      //     resetUploader();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            const obj = {
              about: this.respData.html,
            }

            store
              .dispatch(`${STORE_MODULE_NAME}/update`, obj)
              .then(result => {
                this.show = false
                console.log('fetch Success : ', result)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: this.$t('Success'),
                  },
                })
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
        })
    },
    cancelBtn() {
      this.$router.go(-1)
    },
    showHtml() {
      console.log(this.respData.htmlData)
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";

  .ql-container {
    height: 250px;
  }
  </style>
